<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :referenceData="referenceData"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'
import workflow from '@/workflows/sanction'

export default {
  name: 'Case',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.sanctions
      },
      set (payload) {
        this.$store.commit('setSanctions', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$store.commit('setBrand', 'Sanctions')
    this.permissions.read = ac.can(this.user.acgroups).readAny('sanction').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('sanction').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'sanction',
      nav: [
        { name: 'Sanctions', text: 'Sanctions' },
        { name: 'SanctionsDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Sanctions',
      permissions: {
        add: false,
        read: false
      },
      referenceData: [
        { route: { name: 'ListSimple', params: { model: 'schannel' } }, model: 'schannel', name: 'channels' },
        { route: { name: 'ListSimple', params: { model: 'scustomer' } }, model: 'scustomer', name: 'customers' },
        { route: { name: 'ListSimple', params: { model: 'sparty' } }, model: 'sparty', name: 'parties' },
        { route: { name: 'ListSimple', params: { model: 'sproduct' } }, model: 'sproduct', name: 'products' },
        { route: { name: 'ListSimple', params: { model: 'ssector' } }, model: 'ssector', name: 'sectors' },
        { route: { name: 'ListSimple', params: { model: 'stype' } }, model: 'stype', name: 'types' }
      ],
      searchProperties: ['name', 'body', 'keywords', 'urn', 'link'],
      staticDataProperties: [
        { name: 'Channel', property: 'schannels', graph: 'schannels' },
        { name: 'Country', property: 'countries', graph: 'countries' },
        { name: 'Customer', property: 'scustomers', graph: 'scustomers' },
        { name: 'Party', property: 'sparties', graph: 'sparties' },
        { name: 'Product', property: 'sproducts', graph: 'sproducts' },
        { name: 'Sector', property: 'ssectors', graph: 'ssectors' },
        { name: 'Typer', property: 'stypes', graph: 'stypes' },
        { name: 'Regulator', property: 'regulators', graph: 'regulators' }
      ],
      sortDirection: 'desc',
      sortProperty: 'created_at',
      sortPropertyOptions: [
        { value: 'name', text: 'title' },
        { value: 'created_at', text: 'date created' },
        { value: 'start', text: 'date sanction started' },
        { value: 'end', text: 'date sanction ended' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = {}
        staticData.workflow = workflow
        _.each(['countries', 'regulators', 'schannels', 'scustomers', 'sparties', 'sproducts', 'ssectors', 'stypes'], p => {
          let set = []
          _.each(this.rows, row => {
            _.each(row[p], x => {
              if (x) {
                set.push(x)
              }
            })
          })
          set = _.uniqBy(set, 'id')
          set = _.sortBy(set, ['name'])
          staticData[p] = set
        })
        this.staticData = staticData
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/sanctions/limit/${limit}/offset/${offset}`)
          response.forEach(x => {
            const w = workflow.find(y => y.id === x.workflow_id)
            x.workflow = [{
              id: x.workflow_id,
              name: w.name
            }]
          })
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
